// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Divider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Divider.tsx");
}
// REMIX HMR END

import styles from './Divider.module.css';
import clsx from 'clsx';
export function Divider({
  outgoingColor,
  incomingColor,
  className
}) {
  return <div aria-hidden={true} className={clsx(styles.divider, className)} style={{
    ...(outgoingColor ? {
      '--outgoing-divider-color': outgoingColor
    } : {}),
    ...(incomingColor ? {
      '--incoming-divider-color': incomingColor
    } : {})
  }}>


      <svg viewBox="0 0 40 40" fill="none">
        <path d="M0,0 C0,0 0,40 40,40 h-40" />
      </svg>

      <svg viewBox="0 0 40 40" fill="none">
        <path d="M0,0 C0,0 40,0 40,40 v-40" />
      </svg>
    </div>;
}
_c = Divider;
var _c;
$RefreshReg$(_c, "Divider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;